$bgPath: "assets/img/minigame/bg.png";
$bgSuccessPath: "assets/img/minigame/bg_success.png";
$topPath: "assets/img/minigame/top.svg";
$centerPath: "assets/img/minigame/center.svg";
$wheelBgPath: "assets/img/minigame/wheel-bg.svg";

.sevenup__container {
  margin: 0 auto;
  background-color: #00b453;
  display: table;
  width: 100%;
  height: 100vh;

  &__main {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: rgba(248, 247, 249, 0.2);
    // top: 50%;
    // left: 50%;
    // margin-top: -250px;
    // margin-left: -150px;
    // padding: 10px 40px;
    // border-radius: 30px;
    // transform: translate(-50%, -50%);
    left: 0;
    transition: left 0.3s;
    z-index: 2;
  }

  &__background,
  &__background__success {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__background {
    background: url($bgPath);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .wheel__bg {
    background: url($wheelBgPath) no-repeat;
    width: 335px;
    height: 335px;
    position: relative;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .wheel {
    top: 27px;
    left: 27px;
    width: 274px;
    height: 274px;
    border-radius: 50%;
    border: solid 2px #fff;
    position: relative;
    cursor: pointer;

    &__inner {
      width: 270px;
      height: 270px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      transition: cubic-bezier(0.19, 1, 0.22, 1) 5s;
    }
    &__top_arrow {
      background: url($topPath) no-repeat;
      background-position: 10px;
      width: 50px;
      height: 50px;
      position: absolute;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Open sans", sans-serif;
      z-index: 99;
      top: -40px;
      left: 40%;
      // animation: a-rotate 5s;
    }
    @keyframes a-rotate {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-15deg);
      }
      50% {
        transform: rotate(-7deg);
      }
      75% {
        transform: rotate(-3deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    &__sec {
      position: absolute;
      top: 0px;
      left: 75px;
      width: 0;
      height: 0;
      border: solid;
      border-width: 135px 60px 0;
      border-color: transparent;
      transform-origin: 50% 100%;

      &:nth-child(1) {
        border-top-color: #fff200;
      }
      &:nth-child(2) {
        border-top-color: #00b453;
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        border-top-color: #fff200;
        transform: rotate(90deg);
      }
      &:nth-child(4) {
        border-top-color: #00b453;
        transform: rotate(135deg);
      }
      &:nth-child(5) {
        border-top-color: #fff200;
        transform: rotate(180deg);
      }
      &:nth-child(6) {
        border-top-color: #00b453;
        transform: rotate(225deg);
      }
      &:nth-child(7) {
        border-top-color: #fff200;
        transform: rotate(270deg);
      }
      &:nth-child(8) {
        border-top-color: #00b453;
        transform: rotate(315deg);
      }

      img {
        width: 64px;
        height: 64px;
        position: absolute;
        left: -32px;
        top: -125px;
        object-fit: contain;
      }
    }
    &__text {
      margin: 70px 0 0 70px;
    }
    &__arrow {
      background: url($centerPath) no-repeat;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 110px;
      left: 41%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Open sans", sans-serif;
    }
    &__button {
      width: 60px;
      height: 60px;
      background-color: lightgray;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 600;
      font-size: 18px;
      &:hover {
        color: #27ae60;
      }
      &:active {
        border: solid 3px rgba(0, 0, 0, 0.1);
        font-size: 15px;
      }
    }
  }

  &__main__success {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    opacity: 0;
    z-index: 1;
  }
  &__background__success {
    background: url($bgSuccessPath);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__title {
    box-sizing: border-box;
    top: 40%;
    left: 60%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;

    p, span {
      display: inline-block;
      font-family: "iCiel Pequena", sans-serif;
      font-size: 38px;
      color: #fff;
      line-height: 40px;
      -webkit-text-stroke: 2px green;
      transform: rotate(-17deg);
      word-spacing: -7px;
      text-transform: uppercase;
      font-weight: bold;
      width: 300px;
      position: relative;
      // right: -34px;
      // top: 300px;
      z-index: 1;
      text-align: center;
      text-shadow: 0px 6px 0px #fbd823, 
      3px 5px 0px #fbd823, 
      0px 3px 0px #fbd823,
      4px 0px 0px #fbd823,
      0px 6px 0px #fbd823,
      4px -3px 0px #fbd823,
      0px -3px 0px #fbd823,
      -3px -3px 0px #fbd823,
      -3px -2px 0px #fbd823,
      -3px -1px 0px #fbd823,
      -3px 0px 0px #fbd823,
      -3px 6px 0px #fbd823,
      0px 12px 0 #035711, 6px 10px 0 #035711;
    }
    // span + p {
    //   font-size: 39px;
    //   width: 240px;
    // }
    p {
      margin: 0;
      font-size: 42px;
      text-align: center;
      padding-top: 5px;
    }
  }

  &__img__succes {
    position: relative;
    top: 50vh;
    height: 38vh;
    width: 70vw;
    left: 15vw;

    img {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .success__arrow {
    position: absolute;
    bottom: 0;
    text-align: center;
    right: 0;
    left: 0;
    padding: 20px;

    button {
      margin: 0 auto;
      color: #00b452;
      background-color: #ffca28;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

/** Responsive **/
@media (max-width: 420px) {
}
