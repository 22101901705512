$bgPath: "assets/img/minigame/liese/bg.jpg";
$bgSuccessPath: "assets/img/minigame/liese/bg.jpg";
$topPath: "assets/img/minigame/top.svg";
$centerPath: "assets/img/minigame/liese/center.png";
$wheelBgPath: "assets/img/minigame/liese/wheel.png";

.liese__container {
  margin: 0 auto;
  background-color: #eac9e2;
  display: table;
  width: 100%;
  height: 100vh;

  &__main {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: rgba(248, 247, 249, 0.2);
    // top: 50%;
    // left: 50%;
    // margin-top: -250px;
    // margin-left: -150px;
    // padding: 10px 40px;
    // border-radius: 30px;
    // transform: translate(-50%, -50%);
    left: 0;
    transition: left 0.3s;
    z-index: 4;
  }

  &__background,
  &__background__success {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__background {
    background: url($bgPath);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  .wheel__bg {
    background: url($wheelBgPath) no-repeat;
    width: 435px;
    height: 435px;
    position: relative;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
  }
  .wheel {
    top: 0;
    left: 40px;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    // border: solid 3px #fff;
    position: relative;
    cursor: pointer;
    z-index: 5;

    &__bottom {
      // .line {
      //   font-family: "GMV DIN Pro";
      //   position: absolute;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   z-index: 99;
      //   bottom: 20vh;
      //   left: 0;
      //   color: white;
      //   text-transform: uppercase;
      //   font-size: 18px;
      //   font-weight: 600;
      // }

      font-family: "GMV DIN Pro";
      position: relative;
      display: block;
      z-index: 99;
      // color: white;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      transform: translate(-50%, -40%);
      top: calc(77% - 335px);
      left: 50%;
      bottom: 0;
    }

    &__result {
      font-family: "GMV DIN Pro";
      position: relative;
      display: block;
      top: -240px;
      color: white;
      left: 80px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
    }

    &__inner {
      width: 336px;
      height: 336px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      transition: cubic-bezier(0.19, 1, 0.22, 1) 5s;
      z-index: 3;
      top: 50px;
      left: 50px;
    }

    @keyframes a-rotate {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-15deg);
      }
      50% {
        transform: rotate(-7deg);
      }
      75% {
        transform: rotate(-3deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    &__sec {
      position: absolute;
      top: 0px;
      left: 67px;
      width: 0;
      height: 0;
      border: solid;
      // border-width: 135px 80px 0;
      border-width: 170px 100px 0;
      border-color: transparent;
      transform-origin: 50% 100%;
      z-index: 1;

      &:nth-child(1) {
        border-top-color: #94d5ef;
      }
      &:nth-child(2) {
        border-top-color: #e85c7a;
        transform: rotate(60deg);
      }
      &:nth-child(3) {
        border-top-color: #fed9cf;
        transform: rotate(120deg);
      }
      &:nth-child(4) {
        border-top-color: #eac9e2;
        transform: rotate(180deg);
      }
      &:nth-child(5) {
        border-top-color: #ffb711;
        transform: rotate(240deg);
      }
      &:nth-child(6) {
        border-top-color: #6cceaf;
        transform: rotate(300deg);
      }

      img {
        width: 65px;
        height: 65px;
        position: absolute;
        left: -35px;
        top: -135px;
        object-fit: contain;
      }
    }
    &__text {
      margin: 70px 0 0 70px;
    }
    &__arrow {
      background: url($centerPath) no-repeat;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 45%;
      left: 45%;
      // border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Open sans", sans-serif;
      z-index: 4;
      background-size: contain;
    }
    &__button {
      width: 60px;
      height: 60px;
      background-color: lightgray;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 600;
      font-size: 18px;
      &:hover {
        color: #27ae60;
      }
      &:active {
        border: solid 3px rgba(0, 0, 0, 0.1);
        font-size: 15px;
      }
    }
  }

  &__main__success {
    width: 100%;
    height: 100%;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    opacity: 0;
    z-index: 3;
    position: absolute;
    left: 0;
  }

  &__img__succes {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }

  &__main__thanks {
    width: 100%;
    height: 100%;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    opacity: 0;
    z-index: 2;
    position: absolute;
    left: 0;
  }

  // &__img__succes {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;

  //   img {
  //     object-fit: fill;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }

  .success__text {
    position: absolute;
    bottom: calc(50% - 275px);
    text-align: center;
    right: 0;
    left: 0;
    padding: 20px;
    line-height: 35px;

    span {
      font-family: "GMV DIN Pro";
      position: relative;
      display: block;
      z-index: 99;
      color: white;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      transform: translate(-50%, -40%);
      top: calc(77% - 335px);
      left: 50%;
      bottom: 0;
    }

    span:nth-child(1) {
      font-size: 18px;
    }
    span:nth-child(2) {
      font-size: 26px;
      font-weight: 900;
    }
  }

  .success__arrow {
    position: absolute;
    bottom: 0;
    text-align: center;
    right: 0;
    left: 0;
    padding: 20px;

    button {
      margin: 0 auto;
      color: #0074bd;
      background-color: #eaf6fd;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

/** Responsive **/
@media (max-width: 420px) {
}
