@font-face {
    font-family: 'iCiel Pequena';
    src: url('iCielPequena.eot');
    src: url('iCielPequena.eot?#iefix') format('embedded-opentype'),
        url('iCielPequena.woff2') format('woff2'),
        url('iCielPequena.woff') format('woff'),
        url('iCielPequena.ttf') format('truetype'),
        url('iCielPequena.svg#iCielPequena') format('svg');
    font-weight: normal;
    font-style: normal;
}

