* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

$logoPath: "./assets/img/logo.svg";
$logoColorPath: "./assets/img/logo-color.svg";
$logoPathMobile: "./assets/img/logo.svg";
$iconBtnAdd: "./assets/img/create.svg";
$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;
$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-1: #224082;
$theme-color-2: #3498ff;
$theme-color-3: #3498ff8c;
$theme-color-4: #2296f3;
$theme-color-imgage-2: linear-gradient(90deg, #6384d3, #365293);
$foreground-color: white;
$primary-color: #212121;
$button-text-color: #fff;
$input-background: white;

@import 'sevenup';
@import 'hygent';
@import 'liese';
@import 'iCielPequena';

@font-face {
  font-family: "UTMHelve";
  src: url("./assets/fonts//utm-helve.ttf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  height: 100%;
}

body {
  font-family: UTMHelve, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: #212121;
  // background: #bdc3c6;
  box-sizing: border-box;
  overflow-x: hidden;
  background-image: linear-gradient(to right top, #e4f3ff, #edf5ff, #f5f8ff, #fbfbff, #ffffff);
}

.panel-right {
  position: absolute;
  top: -50px;
  right: 20px;
}
.panel-right-mini {
  position: absolute;
  top: 10px;
  right: 20px;
}

.error-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .item {
    width: 100vh;
    text-align: center;
  }
  img {
    height: 260px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

// NOTIFICATION
.notification--form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
}
// END NOTIFICATION

.card {
  width: auto;
  height: 370px;
  background-color: $foreground-color;
  margin-bottom: 24px;
  border-radius: 12px;
  transition: all 0.25s ease;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  &--mini {
    min-width: 250px;
  }
  &--lg {
    height: 520px;
  }
  &--title {
    font-size: 16px;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #344b60;
  }
  &--title-right {
    position: absolute;
    right: 25px;
  }
  &--head-inline {
    display: inline-flex;
    align-items: center;

    p {
      margin-right: 25px;
    }
  }
  &--subtitle {
    margin: 10px 0px;

    & > * {
      margin: 0px 5px;
    }
  }
}

.card-super-mini {
  margin: 0 auto;
  height: unset;
  max-width: 480px;
  min-width: 150px;
  background-color: $foreground-color;
  margin-bottom: 24px;
  border-radius: 12px;
  transition: all 0.25s ease;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  p {
    font-size: 40px;
    font-weight: bold;
  }

  q {
    font-size: 14px;
    font-weight: bold;
  }

  .rs-divider-with-text {
    margin: 18px 0px !important;
  }
}

.custom-timeline {
  margin-left: 20px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
  position: absolute;
  background: #fff;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  padding-top: 5px;
  color: #999;
  margin-left: -8px;
}

.custom-timeline .rs-timeline-item-content {
  margin-left: 24px;
}

/** Analytics **/
.analytics__container {
  margin: 0px 15px;
  max-width: 1200px;

  .leaflet-div-icon {
    background: unset;
    border: unset;
  }

  &--pie {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  &--component-height-mini {
    height: 300px;
  }

  &--total-count {
    text-transform: uppercase;
    text-align: center;
  }
  &--list-col-right-center {
    display: flex;
    justify-content: center;
  }
  &--list-no-shadow {
    box-shadow: unset;
    border-bottom: 1px solid #e5e5ea;
  }
}
/** END Analytics **/

/** TABLE Images **/
.modal-wrapper-img {
  width: auto;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.table-setting {
  .wrapper-img {
    width: 40px;
    height: 40px;
    margin-top: 2px;
    overflow: hidden;
    display: inline-block;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .radius {
    border-radius: 50%;
  }
}
/** END TABLE Images **/

/** Dropzone **/
.dropzone {
  min-height: 115px !important;
  border: 1px solid $separator-color !important;
  background: $input-background !important;
  padding: 10px 10px !important;
  border-radius: $border-radius !important;
  color: $primary-color !important;
  height: auto !important;

  .img-thumbnail {
    height: 58px !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid $separator-color !important;
  border-radius: $border-radius !important;
  background: $foreground-color !important;
  color: $primary-color !important;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: $theme-color-1;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: $theme-color-1 !important;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: $primary-color !important;
  }

  .dz-error-mark {
    color: $button-text-color !important;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor(
          $theme-color-1
        ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor(
          $foreground-color
        ) + ";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:" + encodecolor(
          $foreground-color
        ) + ";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-success-mark {
    color: $button-text-color;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor(
          $theme-color-1
        ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor(
          $foreground-color
        ) + ";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
      background: $theme-color-1 !important;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color !important;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color !important;
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
    color: $theme-color-1 !important;
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}
/** END Dropzone **/

.customers__container {
  margin: 0px 15px;
  max-width: 1200px;

  &--list {
    min-height: 250px;
    margin-bottom: 24px;
    background-color: $foreground-color;
  }

  &--heading {
    & > div {
      margin: 10px 0px;
    }

    .card--subtitle {
      margin: auto -10px;
    }
  }
}

/** Point **/
.point__container {
  margin: 0px 15px;
  max-width: 1200px;

  &--item {
    min-height: 250px;
    margin-bottom: 24px;
    background-color: $foreground-color;
  }
  &--item-panel {
    position: relative;
  }
  &--location {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
  &--map {
    width: 100%;
    height: 100vh;
  }
  &--calendar {
    width: 280px;
  }
}

/** Accounts **/
.accounts__container {
  margin: 0px 15px;
  max-width: 1200px;

  &--list {
    min-height: 250px;
    margin-bottom: 24px;
    background-color: $foreground-color;
  }

  .account_heading {
    // margin: 24px 0px;

    & > div {
      margin: 10px 0px;
    }
  }
}
/** END Accounts **/

/** Gifts **/
.gifts__container {
  margin: 0px 15px;
  max-width: 1200px;

  &--list {
    min-height: 250px;
    margin-bottom: 24px;
    background-color: $foreground-color;
  }

  .gift_heading {
    margin: 24px 0px;

    & > div {
      margin: 10px 0px;
    }
  }

  .gift_total {
    text-align: center;
  }

  .gift_card {
    background-color: #fff;
    height: 240px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    &:hover {
      .overlay {
        display: none;
      }

      .mid {
        display: none;
      }

      img {
        opacity: 1;
      }
    }

    .overlay {
      background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5));
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
    img {
      width: 100%;
      object-fit: cover;
      position: relative;
      float: left;
      height: 240px;
      opacity: 0.5;
    }

    .mid {
      font-family: "Roboto", sans-serif;
      color: #444;
      text-transform: uppercase;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      z-index: 5;
      width: 100%;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 2px;

      p {
        border-bottom: 1px dotted;
        font-weight: 500;
      }
      span {
        line-height: 25px;
        font-size: 14px;
        letter-spacing: 2px;
        text-align: left;
      }
      span:nth-child(2) {
        float: right;
      }
    }

    .icon-update,
    .icon-del {
      position: absolute;
      bottom: 0;
      padding: 8px 11px;
      color: #fff;
      z-index: 10;

      &:hover {
        padding: 8px 11px;
        opacity: 1;
        border: 1px dotted #efefef;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .icon-update {
      left: 15px;
    }
    .icon-del {
      right: 15px;
    }
  }
}
/** End Gifts **/

/** Footer */
.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  .wave {
    background: url(assets/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 37s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.5;
  }

  // .wave:nth-of-type(2) {
  //   top: -175px;
  //   animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  //   opacity: 1;
  // }

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  // @keyframes swell {
  //   0%,
  //   100% {
  //     transform: translate3d(0, -25px, 0);
  //   }
  //   50% {
  //     transform: translate3d(0, 5px, 0);
  //   }
  // }
}

/** End Footer */

/**
Login Form
*/
input.login-container__username::placeholder,
input.login-container__password::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}
.login-container {
  margin: 0 auto;
  background-color: $theme-color-1;
  display: table;
  width: 100%;
  height: 100vh;

  .login-container__language {
    float: right;
  }

  .login-container__form {
    position: absolute;
    width: 350px;
    height: 480px;
    background-color: rgba(248, 247, 249, 0.2);
    top: 50%;
    left: 50%;
    // margin-top: -250px;
    // margin-left: -150px;
    padding: 10px 40px;
    border-radius: 30px;
    transform: translate(-50%, -50%);

    .side-drawer-logo-container {
      text-align: center;
      margin: 30px;
      margin-top: 70px;

      .side-drawer-account-container {
        min-height: 100px;
        text-align: center;
        position: relative;

        .side-drawer-accounts__avatar {
          position: absolute;
          left: 0;
          top: 0;

          img {
            width: 72px !important;
            height: 72px !important;
            border-radius: 50%;
          }
        }
        .side-drawer-accounts__name {
          color: #fff;
          position: absolute;
          right: 0px !important;
        }
      }
    }

    .side-drawer-logo {
      width: 110px;
      height: 100px;
      background: url($logoPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
    }

    .login-container__input {
      font-size: 16px;
      color: #f9f9f9;

      .login-container__username {
        font-size: 18px;
        padding: 20px;
        margin-bottom: 15px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-username.svg") top left no-repeat;
        background-size: 23px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }
      .login-container__password {
        margin-top: 15px;
        font-size: 18px;
        padding: 20px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-password.svg") top left no-repeat;
        background-size: 21px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }

      .login-container__checkbox-wraper {
        padding: 5px 0px 20px 0px;

        .login-container__checkbox {
          font-size: 15px;
          user-select: none;
          margin-left: -5px;

          label:before {
            background-color: rgba(78, 143, 255, 0.8);
          }
        }
      }
    }

    .login-container__btn-wraper {
      text-align: center;
      .login-container__btn {
        margin: 0 auto;
        background-color: rgba(78, 143, 255, 0.8);
        width: 100%;
        border: 1px solid rgba(78, 143, 255, 1);
        border-radius: 20px;
        font-size: 20px;
        color: #fff;

        &:hover {
          background-color: rgba(96, 154, 255, 0.8);
          color: #f9f9f9;
        }
      }
    }
  }
}
/** End Login **/

/** Toolbar **/
.toolbar {
  background-color: #fff;
  width: 100%;
  height: 65px;
  font-size: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  // position: fixed;
  z-index: 2;

  .toolbar__navigation {
    display: inline-flex;
    height: 100%;
    width: 100%;
    align-items: center;

    .side-drawer-logo {
      width: 90px;
      height: 40px;
      background: url($logoColorPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
      margin: 0px 10px;
      cursor: pointer;
    }

    .login-container__language {
      position: absolute;
      right: 0;
    }

    .toolbar_navigation-items {
      position: absolute;
      right: 60px;
    }

    .login-container__language,
    .toolbar_navigation-items {
      a {
        z-index: 1;
      }
    }
  }
}
/** End Toolbar **/

/* Loading */
.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($theme-color-1, 0.2);
  border-radius: 50%;
  border-top-color: $theme-color-1;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/* End Loading */

/** Body Overview**/
#app-container {
  flex: 1 0 auto;
  position: relative;
}
.body-overview {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.min-h-500 {
  min-height: 500px;
}
/** End Body Overview**/

/** Event **/
.event__container {
  margin: 0px 15px;

  .account-container {
    cursor: pointer;

    .account-container__avatar {
      border-radius: 50%;
      border: 1px solid #efefef;
      margin-left: 5px;

      img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-top: -5px;
      }
    }
    .account-container__more {
      border-radius: 50%;
      border: 1px solid #efefef;
      padding: 16px 16px;
      margin-left: -15px;
      background: #f7f7f7;
    }

    .account-container__avatar:hover,
    .account-container__more:hover {
      border: 2px solid #efefef;
    }
    .account-container__add {
      // border-radius: 50%;
      // border: 1px solid #efefef;
      width: 25px;
      height: 22px;
      padding: 15px 20px;
      color: $theme-color-3;
    }
  }

  .event_heading {
    margin: 24px 0px;

    & > div {
      margin: 10px 0px;
    }
  }

  .event__card {
    background-color: $foreground-color;
    margin-bottom: 24px;
    border-radius: 12px;
    transition: all 0.25s ease;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

    & > .rs-panel-heading {
      font-weight: 600;
    }

    &--container {
      text-transform: uppercase;
      text-align: center;
    }

    &--action {
      position: relative;
    }



  }
}
/** End Event**/

/** Event detail **/
.event_detail__container {
  margin-bottom: 30px;

  .banner {
    position: relative;
    background-color: #3f5972;
    // height: 35vh;
    color: #fff;
    padding: 15px;

    .event_detail__title {
      margin-bottom: 16px;
      max-width: 600px;
      align-items: center;
      border-bottom: 1px solid #344b60;

      p {
        border: 0;
        background: transparent;
        color: white;
        margin: 0;
        border-radius: 0;
        text-indent: 0;
        padding: 16px 0;
        font-size: 26px;
        font-weight: 300;
      }
    }
    .event_detail__time {
      // margin-top: 25px;
      color: white;
      display: flex;
      font-size: 12px;
      align-items: center;

      span {
        letter-spacing: 2px;
        font-size: 14px;
        // line-height: 32px;
        margin-left: 5px;
      }

      .time {
        margin: 0px 10px;
      }

      .text {
        border-bottom: 1px dotted;
        padding-bottom: 1px;
      }
    }

    .event_detail__active {
      position: absolute;
      top: 40px;
      right: 20px;
      bottom: 0;

      .close {
        color: rgba(240, 52, 52, 1);
      }

      .active {
        color: rgba(34, 167, 240, 1);
      }
    }
  }

  .custom-nav {
    // min-height: 40px;
    // margin-top: 40px;
    padding-top: 20px;
    position: relative;
    background-color: #3f5972;
    color: #fff;

    span {
      outline: unset;
      display: block;
      font-size: 16px;
      font-weight: normal;
      color: white;
      border-radius: 12px 12px 0 0;
      padding: 12px 25px;

      &:hover {
        color: white;
      }
    }

    a {
      color: unset;
    }

    .active {
      li > span {
        background: #fafafa;
        color: #344b60;
      }
    }
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }
    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }

  .background {
    width: 100vw;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #233a5f;
    overflow: hidden;
  }
  .background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 35s linear infinite;
  }

  .background li:nth-child(0) {
    left: 74%;
    width: 21px;
    height: 21px;
    bottom: -21px;
    animation-delay: 1s;
  }
  .background li:nth-child(1) {
    left: 52%;
    width: 19px;
    height: 19px;
    bottom: -19px;
    animation-delay: 1s;
  }
  .background li:nth-child(2) {
    left: 85%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 1s;
  }
  .background li:nth-child(3) {
    left: 19%;
    width: 3px;
    height: 3px;
    bottom: -3px;
    animation-delay: 7s;
  }
  .background li:nth-child(4) {
    left: 24%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 15s;
  }
  .background li:nth-child(5) {
    left: 0%;
    width: 12px;
    height: 12px;
    bottom: -12px;
    animation-delay: 13s;
  }
  .background li:nth-child(6) {
    left: 54%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 16s;
  }
  .background li:nth-child(7) {
    left: 62%;
    width: 11px;
    height: 11px;
    bottom: -11px;
    animation-delay: 30s;
  }
  .background li:nth-child(8) {
    left: 25%;
    width: 7px;
    height: 7px;
    bottom: -7px;
    animation-delay: 31s;
  }
  .background li:nth-child(9) {
    left: 0%;
    width: 1px;
    height: 1px;
    bottom: -1px;
    animation-delay: 34s;
  }
  .background li:nth-child(10) {
    left: 82%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 35s;
  }
  .background li:nth-child(11) {
    left: 29%;
    width: 24px;
    height: 24px;
    bottom: -24px;
    animation-delay: 27s;
  }
  .background li:nth-child(12) {
    left: 37%;
    width: 20px;
    height: 20px;
    bottom: -20px;
    animation-delay: 58s;
  }
  .background li:nth-child(13) {
    left: 79%;
    width: 28px;
    height: 28px;
    bottom: -28px;
    animation-delay: 21s;
  }
  .background li:nth-child(14) {
    left: 37%;
    width: 28px;
    height: 28px;
    bottom: -28px;
    animation-delay: 44s;
  }
  .background li:nth-child(15) {
    left: 36%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 10s;
  }
  .background li:nth-child(16) {
    left: 68%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 21s;
  }
  .background li:nth-child(17) {
    left: 60%;
    width: 18px;
    height: 18px;
    bottom: -18px;
    animation-delay: 44s;
  }
  .background li:nth-child(18) {
    left: 43%;
    width: 9px;
    height: 9px;
    bottom: -9px;
    animation-delay: 84s;
  }
  .background li:nth-child(19) {
    left: 48%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 32s;
  }
  .background li:nth-child(20) {
    left: 20%;
    width: 9px;
    height: 9px;
    bottom: -9px;
    animation-delay: 22s;
  }
  .background li:nth-child(21) {
    left: 45%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 6s;
  }
  .background li:nth-child(22) {
    left: 79%;
    width: 18px;
    height: 18px;
    bottom: -18px;
    animation-delay: 60s;
  }
  .background li:nth-child(23) {
    left: 27%;
    width: 19px;
    height: 19px;
    bottom: -19px;
    animation-delay: 4s;
  }
  .background li:nth-child(24) {
    left: 9%;
    width: 29px;
    height: 29px;
    bottom: -29px;
    animation-delay: 39s;
  }
  .background li:nth-child(25) {
    left: 16%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 79s;
  }
  .background li:nth-child(26) {
    left: 21%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 122s;
  }
  .background li:nth-child(27) {
    left: 80%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 11s;
  }
  .background li:nth-child(28) {
    left: 10%;
    width: 27px;
    height: 27px;
    bottom: -27px;
    animation-delay: 76s;
  }
  .background li:nth-child(29) {
    left: 47%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 92s;
  }
  .background li:nth-child(30) {
    left: 33%;
    width: 20px;
    height: 20px;
    bottom: -20px;
    animation-delay: 55s;
  }
  .background li:nth-child(31) {
    left: 65%;
    width: 22px;
    height: 22px;
    bottom: -22px;
    animation-delay: 153s;
  }
  .background li:nth-child(32) {
    left: 32%;
    width: 16px;
    height: 16px;
    bottom: -16px;
    animation-delay: 64s;
  }
  .background li:nth-child(33) {
    left: 40%;
    width: 29px;
    height: 29px;
    bottom: -29px;
    animation-delay: 108s;
  }
  .background li:nth-child(34) {
    left: 30%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 149s;
  }
  .background li:nth-child(35) {
    left: 66%;
    width: 29px;
    height: 29px;
    bottom: -29px;
    animation-delay: 91s;
  }
  .background li:nth-child(36) {
    left: 75%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 100s;
  }
  .background li:nth-child(37) {
    left: 41%;
    width: 28px;
    height: 28px;
    bottom: -28px;
    animation-delay: 149s;
  }
  .background li:nth-child(38) {
    left: 21%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 168s;
  }
  .background li:nth-child(39) {
    left: 73%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 64s;
  }
  .background li:nth-child(40) {
    left: 80%;
    width: 26px;
    height: 26px;
    bottom: -26px;
    animation-delay: 99s;
  }
  .background li:nth-child(41) {
    left: 1%;
    width: 27px;
    height: 27px;
    bottom: -27px;
    animation-delay: 195s;
  }
  .background li:nth-child(42) {
    left: 38%;
    width: 23px;
    height: 23px;
    bottom: -23px;
    animation-delay: 38s;
  }
  .background li:nth-child(43) {
    left: 18%;
    width: 5px;
    height: 5px;
    bottom: -5px;
    animation-delay: 92s;
  }
  .background li:nth-child(44) {
    left: 2%;
    width: 18px;
    height: 18px;
    bottom: -18px;
    animation-delay: 217s;
  }
  .background li:nth-child(45) {
    left: 35%;
    width: 11px;
    height: 11px;
    bottom: -11px;
    animation-delay: 31s;
  }
  .background li:nth-child(46) {
    left: 58%;
    width: 22px;
    height: 22px;
    bottom: -22px;
    animation-delay: 43s;
  }
  .background li:nth-child(47) {
    left: 34%;
    width: 27px;
    height: 27px;
    bottom: -27px;
    animation-delay: 105s;
  }
  .background li:nth-child(48) {
    left: 73%;
    width: 11px;
    height: 11px;
    bottom: -11px;
    animation-delay: 56s;
  }
  .background li:nth-child(49) {
    left: 38%;
    width: 22px;
    height: 22px;
    bottom: -22px;
    animation-delay: 156s;
  }
}
/** END Event detail **/

.m-0-auto {
  margin: 0 auto;
}
.notfound {
  text-align: center;
  background-color: unset;
  font-weight: 400;
  font-size: 34px;
  line-height: 15px;
  letter-spacing: 2px;
}

.online {
  background: url("./assets/img/dot.svg") top left no-repeat;
  background-size: 5px;
  background-position: 0;
  padding-left: 15px;
}

q {
  quotes: "“" "”" "‘" "’";
}

.disabled {
  color: #ccc;
}

/* insert the quotes using pseudo-elements and the `content` property */

q:before {
  content: open-quote;
}

q:after {
  content: close-quote;
}

.bold {
  font-weight: 600;
}
.mt-5r {
  margin-top: 0.5rem;
}
.mb-5r {
  margin-bottom: 0.5rem;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.mb24 {
  margin-bottom: 24px;
}
.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.p-0 {
  padding: 0 !important;
}
.p-10 {
  padding: 10px !important;
}
.ptb-10 {
  padding: 10px 0px !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.m-1px {
  margin: 1px !important;
}
.text-primary,
.text-theme-1 {
  color: #224082 !important;
}
.text-extra-small {
  font-size: 0.6rem;
}
.text-primary {
  color: #007bff !important;
}
.position-relative {
  position: relative !important;
}
.cursor-pointer {
  cursor: pointer;
}

.rs-table {
  .header-cell-group {
    .rs-table-cell-content {
      padding: 0;
    }
    &-title,
    &-subtitle span {
      padding: 11px;
      border-bottom: 1px solid #f2f2f5;
    }
    &-subtitle span {
      font-size: xx-small;
      display: inline-block;
      border-right: 1px solid #f2f2f5;
    }
    &-value span {
      padding: 10px;
      font-size: smaller;
    }
  }
}

 /*
 * Just a quick hamburger
 */
 .header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  // position: fixed;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;

  .active {
    background-color: #e6e6e6;
  }
}

.header a li span {
  display: block !important;
  padding: 15px 20px !important;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/** Responsive **/
@media (max-width: 479px) {
  .event_detail__container {
    .banner {
      // height: 35vh;

      .event_detail__title p {
        font-size: 30px;
        line-height: 60px;
        min-height: 80px;
      }
      .event_detail__time {
        span {
          font-size: 13px;
          line-height: 30px;
          margin-left: 0px;
        }
      }
      .event_detail__active {
        position: absolute;
        top: 30px;
        right: 20px;
      }
    }
  }
  .customers__container,
  .analytics__container,
  .point__container,
  .accounts__container,
  .gifts__container,
  .event__container {
    margin: 0;
  }

  .point__container {
    &--calendar {
      width: 180px;
    }
    &--heading {
      div {
        margin: 5px 0px;
      }
    }
  }
}
// Small devices (landscape phones, 576px and up)
@media (max-width: 614px) { 
  .custom-nav {
    display: none;
  }

  .hamburger-icon {
    .container {
      display: inline-block;
      cursor: pointer;
    }
  }
}
@media (min-width: 615px) {
  .hamburger-icon {
    .container {
      display: none;
    }
  }
}
@media (min-width: 480px) and (max-width: 575px) {
  .event_detail__container {
    .banner {
      // height: 35vh;

      .event_detail__title p {
        font-size: 30px;
        line-height: 60px;
        min-height: 80px;
      }
      .event_detail__time {
        span {
          font-size: 14px;
          line-height: 30px;
          margin-left: 0px;
        }
      }
      .event_detail__active {
        position: absolute;
        top: 30px;
        right: 20px;
      }
    }
  }
  .customers__container,
  .analytics__container,
  .point__container,
  .accounts__container,
  .gifts__container,
  .event__container {
    margin: 0;
  }

  .accounts__container {
    &__notification {
      display: contents;
    }

    .account_heading {
      .group__button {
        line-height: 3;;
      }
    }
  }

  .point__container {
    &--heading {
      div {
        margin: 5px 0px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
  .customers__container,
  .analytics__container,
  .point__container,
  .accounts__container,
  .gifts__container,
  .event__container {
    margin: 0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
  .analytics__container {
    &--pie {
      margin-top: 30px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .customers__container,
  .analytics__container,
  .gifts__container,
  .accounts__container,
  .point__container {
    margin: 0 auto;
  }

  .analytics__container {
    &--pie {
      margin-top: 15px;
    }
  }

  .card {
    min-width: 370px;
  }
}

/** Animated **/
.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-4-6 22:21:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
